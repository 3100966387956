<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <p>News and Insights</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog1.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 14, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">50 world-changing people -- We lost in the 2010s</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author1.jpg" alt="image">
                                <h6>Sarah Taylor</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog2.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 16, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">Don't buy a tech gift until you read these rules</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author2.jpg" alt="image">
                                <h6>Michel John</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog3.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 18, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">The golden rule of buying a phone as a gift</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author3.jpg" alt="image">

                                <h6>Lucy Eva</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog4.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 14, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">Configure Redux Into Your WordPress Theme</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author2.jpg" alt="image">
                                <h6>Sarah Taylor</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog5.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 16, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">How To Setup Redux In React Next Application</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author3.jpg" alt="image">
                                <h6>Michel John</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog6.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 18, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">How To Resubmit Rejected Item Into ThemeForest?</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author1.jpg" alt="image">
                                <h6>Lucy Eva</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog7.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 14, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">How To The Active Menu Based On URL In Next.JS?</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author3.jpg" alt="image">
                                <h6>Sarah Taylor</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog8.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 16, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">How To Create A Responsive Popup Gallery?</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author2.jpg" alt="image">
                                <h6>Michel John</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog9.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 18, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">WP Custom Post Register & Query</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author1.jpg" alt="image">
                                <h6>Lucy Eva</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/blog/blog-grid" class="prev page-numbers"><i class='bx bxs-arrow-to-left'></i></a>
                    <a routerLink="/blog/blog-grid" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/blog/blog-grid" class="page-numbers">3</a>
                    <a routerLink="/blog/blog-grid" class="page-numbers">4</a>
                    <a routerLink="/blog/blog-grid" class="next page-numbers"><i class='bx bxs-arrow-to-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Free Trial Area -->
<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Practice active listening and follow through</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
        </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image"></div>
</section>
<!-- End Free Trial Area -->