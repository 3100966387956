<!-- Start Free Trial Area -->
<section class="free-trial-area pb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Pratique a escuta ativa e siga em frente</h2>
            <p>Qualifique seus leads e reconheça o valor da palavra que seu cliente vai adorar você</p>
            <a href="https://login.spotchat.com.br/registro" class="default-btn"><i class="bx bxs-hot"></i> Teste Grátis <span></span></a>
        </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image"></div>
</section>
<!-- End Free Trial Area -->