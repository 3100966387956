<!-- Start Main Banner Area -->
<div class="main-banner main-banner-one">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h1>Secure IT Solutions for a more secure environment</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                <a routerLink="/contact" class="default-btn">
                                    <i class="bx bxs-hot"></i>Get Started<span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="banner-image mbanner-bg-one">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="animate-banner-image">
                                <img src="assets/img/animate-banner-img1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                    
                    <img src="assets/img/banner-slider/banner-img1.jpg" class="mbanner-img" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape20"><img src="assets/img/shape/19.png" alt="image"></div>
    <div class="shape21"><img src="assets/img/shape/20.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/18.png" alt="image"></div>
    <div class="shape22"><img src="assets/img/shape/21.png" alt="image"></div>
    <div class="shape23"><img src="assets/img/shape/22.svg" alt="image"></div>
    <div class="shape24"><img src="assets/img/shape/23.png" alt="image"></div>
    <div class="shape26"><img src="assets/img/shape/25.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- Start Features Card -->
<div class="features-card-section pt-100 pb-70 bg-f8fbfa">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <div class="single-features-card tx-center">
                    <i class='bx bx-conversation'></i>
                    <h3>
                        <a routerLink="/single-services">IT Consultancy</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="single-features-card tx-center">
                    <i class='bx bx-laptop'></i>
                    <h3>
                        <a routerLink="/single-services">Web Development</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                <div class="single-features-card tx-center">
                    <i class='bx bxs-badge-check'></i>
                    <h3>
                        <a routerLink="/single-services">IT Solutions</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="single-features-card tx-center">
                    <i class='bx bxs-megaphone'></i>
                    <h3>
                        <a routerLink="/single-services">Digital Marketing</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Features Card -->

<!-- Start Services Area -->
<section class="services-area bg-right-shape ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content it-service-content">
                <div class="content left-content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Web & Mobile Development</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.</p>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>  
                                Responsive Design
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                UI / UX Design
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                Mobile App Development
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                Laravel Web Development
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                React Web Development
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                Angular Web Development
                            </div>
                        </div>
                    </div>

                    <a routerLink="/single-services" class="default-btn">
                        <i class="bx bxs-spreadsheet"></i> 
                        Learn More 
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="services-image wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/services-image/service1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Services Area -->
<section class="services-area bg-left-color bg-left-shape bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/services-image/service2.png" alt="image">
                </div>
            </div>

            <div class="services-content it-service-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Cloud Hosting Services</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.</p>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>  
                                Cloud Databases
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                Hybrid Cloud
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                Email Servers
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                Website Hosting
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                File Storage
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                Backup Systems
                            </div>
                        </div>
                    </div>

                    <a routerLink="/single-services" class="default-btn">
                        <i class="bx bxs-spreadsheet"></i> 
                        Learn More 
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Video Presentation Area -->
<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Watch this video presentation to know more</h2>
        </div>

        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/6.png" alt="image"></div>
        </div>

        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="180">00</span><span class="sign-icon">k</span></h3>
                        <p>Downloaded</p>
                    </div>
                </div>

                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="20">00</span><span class="sign-icon">k</span></h3>
                        <p>Feedback</p>
                    </div>
                </div>

                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="500">00</span><span class="sign-icon">+</span></h3>
                        <p>Workers</p>
                    </div>
                </div>

                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="70">00</span><span class="sign-icon">+</span></h3>
                        <p>Contributors</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="contact-cta-box">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i>Contact Us<span></span></a>
        </div>
    </div>

    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/9.png" alt="image"></div>
</section>
<!-- End Video Presentation Area -->

<!-- Start Features Area -->
<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2>Our amazing features</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".1s">
                    <i class='bx bx-conversation bg-13c4a1'></i>
                    <h3>IT Consultancy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".2s">
                    <i class='bx bxs-badge-check bg-6610f2'></i>
                    <h3>IT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                    <i class='bx bxs-dashboard bg-ffb700'></i>
                    <h3>Simple Dashboard</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".4s">
                    <i class='bx bxs-bell-ring bg-fc3549'></i>
                    <h3>Deadline Reminders</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
                    <i class='bx bxs-info-circle bg-00d280'></i>
                    <h3>Information Retrieval</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".6s">
                    <i class='bx bx-cog bg-ff612f'></i>
                    <h3>Flexible Functionality</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Feedback Area -->
<section class="feedback-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>Spacle</span></h2>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <img src="assets/img/woman1.png" alt="image">

                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Sarah Taylor</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <img src="assets/img/woman2.png" alt="image">

                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Olivar Lucy</h3>
                        <span>CEO at EnvyTheme</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <img src="assets/img/man1.png" alt="image">

                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Feedback Area -->

<app-pricing-plan></app-pricing-plan>

<!-- Start Team Area -->
<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our experts always ready to help you</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>UX/UI Designer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Lee Munroe</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team4.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Calvin Klein</h3>
                        <span>Support</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<app-clients></app-clients>

<!-- Start FAQ Area -->
<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>Spacle</span></h2>

                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on the free plan?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on a free trial?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Does the price go up as my team gets larger?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                How can I cancel/pause my subscription?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Can I pay via invoicing?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- Start Subscribe Content -->
<div class="subscribe-content border-radius-0">
    <h2>We always try to be the best support to you as possible</h2>

    <form class="newsletter-form">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-8">
                <input type="email" class="input-newsletter" placeholder="hello@spacle.com" name="EMAIL">
            </div>

            <div class="col-lg-4 col-md-4">
                <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
            </div>
        </div>
    </form>

    <div class="shape14"><img src="assets/img/shape/13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape/14.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/15.png" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/16.png" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/17.png" alt="image"></div>
</div>
<!-- End Subscribe Content -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog1.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 14, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">50 world-changing people -- We lost in the 2010s</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author1.jpg" alt="image">
                                <h6>Sarah Taylor</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog2.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 16, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">Don't buy a tech gift until you read these rules</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author2.jpg" alt="image">
                                <h6>Michel John</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog3.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 18, 2023</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">The golden rule of buying a phone as a gift</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author3.jpg" alt="image">
                                <h6>Lucy Eva</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="blog-notes">
                    <p>Insights to help you do what you do better, faster and more profitably. <a routerLink="/">Read Full Blog</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->