<!-- Start Page Title Area -->
<div>
    <div class="container">
        <div class="page-title-content">
            <h2>Política de Privacidade e Termos de Uso</h2>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <h2>Respeitamos A Sua Privacidade</h2>
                    <p>
                        O aplicativo SpotChat (“Aplicativo”) é comprometido com a proteção da privacidade dos
                        usuários. Esta Política de Privacidade (“Política”) descreve como coletamos, usamos e
                        compartilhamos informações pessoais e outras informações quando os usuários usam o Aplicativo.
                    </p>
                    <h6>1. Coleta de informações pessoais</h6>
                    <p>Coletamos informações pessoais do usuário, como nome, número de telefone e endereço de
                        e-mail, quando ele se cadastra no Aplicativo ou entra em contato conosco. Essas informações são usadas para fornecer suporte técnico e atendimento ao cliente.
                    </p>
                    <h6>2. Uso de dados</h6>
                    <p>Usamos as informações coletadas para melhorar a experiência do usuário no Aplicativo, como
                        para fornecer suporte técnico e atendimento ao cliente. Também podemos usar essas informações para contatar os usuários com informações importantes sobre o Aplicativo.
                    </p>
                    <h6>3. Armazenamento de dados</h6>
                    <p>As informações do usuário são armazenadas em servidores seguros e protegidas contra acesso não autorizado.</p>
                    <h6>4. Compartilhamento de dados</h6>
                    <p>Não compartilhamos as informações do usuário com terceiros, exceto quando for necessário
                        para cumprir uma obrigação legal ou para proteger os direitos, propriedade ou segurança do Aplicativo ou de terceiros.
                    </p>
                    <h6>5. Direitos do usuário</h6>
                    <p>Os usuários têm o direito de acessar, corrigir e excluir suas informações pessoais. Os usuários
                        também têm o direito de solicitar que paremos de usar suas informações pessoais para fins de marketing.
                    </p>
                    <h6>6. Mudanças na política</h6>
                    <p>Podemos atualizar esta Política de tempos em tempos. Avisaremos os usuários sobre
                        quaisquer alterações significativas na forma como tratamos as informações pessoais, enviando uma notificação para o endereço de e-mail fornecido ou por meio de uma notificação dentro do Aplicativo.
                    </p>
                    <h6>7. Contato</h6>
                    <p>Se os usuários tiverem alguma dúvida ou preocupação pode entrar em contato pelo e-mail contato@spotchat.com.br</p>
                    <h6>8. Das Condições de Uso e Serviços</h6>
                    <p>Os serviços do SpotChat poderão ser interrompidos, inclusive para manutenção, reparos, atualizações, falhas na rede ou equipamentos. O SpotChat poderá descontinuar alguns ou todos os serviços, incluindo determinados recursos e o suporte a determinados dispositivos e plataforma. Eventos fora do controle do SpotChat, como a suspensão da comunicação, poderão afetar os serviços prestados, assim como casos fortuitos ou de força maior. O SpotChat utiliza-se de comunicação não oficial com a plataforma “WhatsApp”, de forma que não garante a continuidade dos serviços caso o “WhatsApp”, por força maior, impeça que essa comunicação continue sendo realizada.</p>
                    <h6>9. Das Renúncias e Limitações de Responsabilidade</h6>
                    <ul>
                        <li><strong>Renúncias:</strong> embora a plataforma esteja sempre online, é possível que haja indisponibilidade periódica por vários motivos. Com exceção do previsto expressamente neste termo e na medida do permitido pela lei aplicável, o serviço é prestado “tal como se apresenta”, e o SpotChat não presta nenhuma garantia de nenhum tipo, seja ela expressa, implícita ou estatutária, inclusive de comerciabilidade, adequação para um fim específico ou não infração, e relativa à disposibilidade, confiabilidade ou precisão do serviço.</li>
                        <li><strong>Exclusão de Responsabilidade:</strong> Na medida do permitido pela lei aplicável, o SpotChat, seus executivos, empregados, agentes, fornecedores e licenciadores não serão responsáveis por quaisquer danos indiretos, emergenciais, especiais, incidentais, punitivos ou exemplares de qualquer tipo, incluindo danos por lucros cessantes, perda de uso e perda de dados, resultantes de ou relacionados ao serviço e este termo, sejam tais danos baseados em contrato, ato ilícito, responsabilidade objetiva ou qualquer outra teoria legal, mesmo que o SpotChat tenha sido advertido da possibilidade dos referidos danos, e mesmo que um recurso não cumpra com seu propósito essencial.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->