<!-- Start Footer Area -->
<footer
    class="footer-area"
    [ngClass]="{'d-none': router.url === '/home-nine' || router.url === '/home-ten' || router.url === '/home-eleven'}"
>
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Suporte Garantido</h3>
                    <p>A melhor plataforma de multi atendimento pelo WhatsApp tem uma equipe para da suporte a você e seu time para utilizar a ferramenta e conseguir extrair os melhores resultados. Vamos ter o maior prazer em atende-los.</p>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Suporte</h3>
                    <ul class="support-list">
                        <li><a routerLink="/about">Privacidade</a></li>
                        <li><a routerLink="/about">Termo de uso</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contato</h3>
                    <ul class="footer-contact-info">
                        <li>Email: <a href="mailto:contato@spotchat.com.br">contatto@spotchat.com.br</a></li>
                        <li>WhatsApp: <a href="https://wa.me/5515997008804?text=Ol%C3%A1%20pode%20me%20ajudar">(15) 99700-8804</a></li>
                    </ul>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/spotchatoficial/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://wa.me/5515997008804?text=Ol%C3%A1%20pode%20me%20ajudar" target="_blank"><i class="bx bxl-whatsapp"></i></a></li>
                        <li><a href="https://www.instagram.com/spotchatoficial/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>Copyright @{{currentYear}} SpotChat.</p>
        </div>
    </div>
</footer>

<footer
    class="footer-area-with-black-color pt-100 d-none"
    [ngClass]="{'d-block': router.url === '/home-nine' || router.url === '/home-ten' || router.url === '/home-eleven'}"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/white-logo.png" alt="image">
                        </a>
                    </div>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.</p>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget ps-5">
                    <h3>Company</h3>
                    <ul class="services-list">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services-1">Services</a></li>
                        <li><a routerLink="/features-1">Features</a></li>
                        <li><a routerLink="/pricing">Our Pricing</a></li>
                        <li><a routerLink="/blog-grid">Latest News</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="support-list">
                        <li><a routerLink="/faq">FAQ's</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/about">Community</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>Location: <a href="https://goo.gl/maps/MQ78iGP5g9VgZcJ38" target="_blank">6th Floor, Elegant Tower, York, USA</a></li>
                        <li>Email: <a href="mailto:hello@spacle.com">hello@spacle.com</a></li>
                        <li>Phone: <a href="tel:+321754754">+0 (321) 984 754</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>Copyright @{{currentYear}} Spacle. Designed by <a href="http://envytheme.com/" target="_blank">EnvyTheme</a></p>
        </div>
    </div>
    <div class="footer-shape-1">
        <img src="assets/img/seo-marketing/footer-shape1.png" alt="image">
    </div>
    <div class="footer-shape-2">
        <img src="assets/img/seo-marketing/footer-shape2.png" alt="image">
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class='bx bx-chevron-up'></i></div>