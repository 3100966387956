<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Right Sidebar</h2>
            <p>News and Insights</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog1.jpg" alt="image"></a>
                                <div class="date"><i class='bx bx-calendar'></i> Oct 14, 2023</div>
                            </div>
                            <div class="post-content">
                                <h3><a routerLink="/blog/blog-details">50 world-changing people -- We lost in the 2010s</a></h3>
                                <div class="post-info">
                                    <div class="post-by">
                                        <img src="assets/img/author-image/author1.jpg" alt="image">
                                        <h6>Sarah Taylor</h6>
                                    </div>
                                    <div class="details-btn">
                                        <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog2.jpg" alt="image"></a>
                                <div class="date"><i class='bx bx-calendar'></i> Oct 16, 2023</div>
                            </div>
                            <div class="post-content">
                                <h3><a routerLink="/blog/blog-details">Don't buy a tech gift until you read these rules</a></h3>
                                <div class="post-info">
                                    <div class="post-by">
                                        <img src="assets/img/author-image/author2.jpg" alt="image">
                                        <h6>Michel John</h6>
                                    </div>
                                    <div class="details-btn">
                                        <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog3.jpg" alt="image"></a>
                                <div class="date"><i class='bx bx-calendar'></i> Oct 18, 2023</div>
                            </div>
                            <div class="post-content">
                                <h3><a routerLink="/blog/blog-details">The golden rule of buying a phone as a gift</a></h3>
                                <div class="post-info">
                                    <div class="post-by">
                                        <img src="assets/img/author-image/author3.jpg" alt="image">
                                        <h6>Lucy Eva</h6>
                                    </div>
                                    <div class="details-btn">
                                        <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog4.jpg" alt="image"></a>
                                <div class="date"><i class='bx bx-calendar'></i> Oct 14, 2023</div>
                            </div>
                            <div class="post-content">
                                <h3><a routerLink="/blog/blog-details">Configure Redux Into Your WordPress Theme</a></h3>
                                <div class="post-info">
                                    <div class="post-by">
                                        <img src="assets/img/author-image/author2.jpg" alt="image">
                                        <h6>Sarah Taylor</h6>
                                    </div>
                                    <div class="details-btn">
                                        <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog5.jpg" alt="image"></a>
                                <div class="date"><i class='bx bx-calendar'></i> Oct 16, 2023</div>
                            </div>
                            <div class="post-content">
                                <h3><a routerLink="/blog/blog-details">How To Setup Redux In React Next Application</a></h3>
                                <div class="post-info">
                                    <div class="post-by">
                                        <img src="assets/img/author-image/author3.jpg" alt="image">
                                        <h6>Michel John</h6>
                                    </div>
                                    <div class="details-btn">
                                        <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog6.jpg" alt="image"></a>
                                <div class="date"><i class='bx bx-calendar'></i> Oct 18, 2023</div>
                            </div>
                            <div class="post-content">
                                <h3><a routerLink="/blog/blog-details">How To Resubmit Rejected Item Into ThemeForest?</a></h3>
                                <div class="post-info">
                                    <div class="post-by">
                                        <img src="assets/img/author-image/author1.jpg" alt="image">
                                        <h6>Lucy Eva</h6>
                                    </div>
                                    <div class="details-btn">
                                        <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog7.jpg" alt="image"></a>
                                <div class="date"><i class='bx bx-calendar'></i> Oct 14, 2023</div>
                            </div>
                            <div class="post-content">
                                <h3><a routerLink="/blog/blog-details">How To The Active Menu Based On URL In Next.JS?</a></h3>
                                <div class="post-info">
                                    <div class="post-by">
                                        <img src="assets/img/author-image/author3.jpg" alt="image">
                                        <h6>Sarah Taylor</h6>
                                    </div>
                                    <div class="details-btn">
                                        <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog8.jpg" alt="image"></a>
                                <div class="date"><i class='bx bx-calendar'></i> Oct 16, 2023</div>
                            </div>
                            <div class="post-content">
                                <h3><a routerLink="/blog/blog-details">How To Create A Responsive Popup Gallery?</a></h3>
                                <div class="post-info">
                                    <div class="post-by">
                                        <img src="assets/img/author-image/author2.jpg" alt="image">
                                        <h6>Michel John</h6>
                                    </div>
                                    <div class="details-btn">
                                        <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/blog/blog-with-right-sidebar" class="prev page-numbers"><i class='bx bxs-arrow-to-left'></i></a>
                            <a routerLink="/blog/blog-with-right-sidebar" class="page-numbers">1</a>
                            <span class="page-numbers current" aria-current="page">2</span>
                            <a routerLink="/blog/blog-with-right-sidebar" class="page-numbers">3</a>
                            <a routerLink="/blog/blog-with-right-sidebar" class="page-numbers">4</a>
                            <a routerLink="/blog/blog-with-right-sidebar" class="next page-numbers"><i class='bx bxs-arrow-to-right'></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class='bx bx-search-alt'></i></button>
                        </form>
                    </section>

                    <section class="widget widget_spacle_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/blog/blog-details" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2023-06-30">June 10, 2023</time>
                                <h4 class="title usmall"><a routerLink="/blog/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog/blog-details" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2023-06-30">June 21, 2023</time>
                                <h4 class="title usmall"><a routerLink="/blog/blog-details">I Used The Web For A Day On A 50 MB Budget</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog/blog-details" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2023-06-30">June 30, 2023</time>
                                <h4 class="title usmall"><a routerLink="/blog/blog-details">How To Create A Responsive Popup Gallery?</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/">Business</a></li>
                            <li><a routerLink="/">Privacy</a></li>
                            <li><a routerLink="/">Technology</a></li>
                            <li><a routerLink="/">Tips</a></li>
                            <li><a routerLink="/">Uncategorized</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>

                        <ul>
                            <li><a routerLink="/">May 2023</a></li>
                            <li><a routerLink="/">April 2023</a></li>
                            <li><a routerLink="/">June 2023</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>

                        <ul>
                            <li><a routerLink="/">Log in</a></li>
                            <li><a routerLink="/">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">WordPress.org</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Spacle <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Free Trial Area -->
<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Practice active listening and follow through</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
        </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image"></div>
</section>
<!-- End Free Trial Area -->