<!-- Start Main Banner Area -->
<div class="main-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h1>Construa sua marca conectando-se com os clientes</h1>
                                <p>Construa uma marca conectada com os clientes através do multiatendimento: autenticidade, narrativa envolvente, presença nas plataformas, experiência excepcional e adaptação às necessidades.</p>
                                <a href="https:/login.spotchat.com.br/registro" class="default-btn"><i class="bx bxs-hot"></i>Teste Grátis<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="banner-image bg-2">
                    <app-chatting></app-chatting>

                    <img src="assets/img/banner-img2.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape20"><img src="assets/img/shape/19.png" alt="image"></div>
    <div class="shape21"><img src="assets/img/shape/20.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/18.png" alt="image"></div>
    <div class="shape22"><img src="assets/img/shape/21.png" alt="image"></div>
    <div class="shape23"><img src="assets/img/shape/22.svg" alt="image"></div>
    <div class="shape24"><img src="assets/img/shape/23.png" alt="image"></div>
    <div class="shape26"><img src="assets/img/shape/25.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<app-partner></app-partner>

<!-- Start Services Area -->
<section class="services-area bg-right-color ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content">
                <div class="content left-content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Múltiplos atendimentos Personalizado</h2>
                    <p>Com o recurso de Múltiplos Atendimentos Personalizado do SpotChat, é possível criar filas de atendimentos para melhor organizar e priorizar as conversas dos usuários. Essa funcionalidade permite que as mensagens recebidas sejam direcionadas para uma fila de espera, onde os atendentes podem acessar e atender as conversas de acordo com a ordem estabelecida.</p>
                    <a href="https://login.spotchat.com.br/registro" class="default-btn"><i class="bx bxs-spreadsheet"></i> Teste Grátis<span></span></a>
                </div>
            </div>

            <div class="services-image">
                <div class="image">
                    <img src="assets/img/services-image/services1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Services Area -->
<section class="services-area bg-left-color bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/services-image/services2.png" alt="image">
                </div>
            </div>

            <div class="services-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Aprimorando a Comunicação</h2>
                    <p>O Múltiplos Atendimentos Personalizado do SpotChat melhora a comunicação com os clientes, permitindo gerenciar equipes de atendimento e mensagens recebidas de forma eficiente. Com filas de atendimento e respostas personalizadas, oferece um atendimento ágil e organizado. Além disso, monitora o desempenho da equipe, promove colaboração interna e proporciona interações satisfatórias aos clientes. Em resumo, é uma ferramenta que aprimora a comunicação e o gerenciamento para uma experiência de suporte excepcional.</p>
                    <a href="https:/login.spotchat.com.br/registro" class="default-btn"><i class="bx bxs-spreadsheet"></i> Teste Grátis<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Video Presentation Area -->
<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Tudo que você precisa, SPOTCHAT!</h2>
        </div>

        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=WkOQv0Rmf8o" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
        </div>

        <div class="contact-cta-box">
            <h3>Fale com nosso time</h3>
            <p>Solicite uma apresentação.</p>
            <a href="https://wa.me/5515997008804?text=Ol%C3%A1%20pode%20me%20ajudar" class="default-btn"><i class="bx bxs-edit-alt"></i>WhatsApp<span></span></a>
        </div>
    </div>

    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/9.png" alt="image"></div>
</section>
<!-- End Video Presentation Area -->

<!-- Start Features Area -->
<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2>Soluções de Atendimento</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>Múltiplas conversas</h3>
                    <p>Múltiplos atendentes com um único número de WhatsApp é extremamente conveniente, pois simplifica o processo de gerenciamento das conversas.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-customize'></i>
                    </div>
                    <h3>Transferência</h3>
                    <p>O atendente pode transferir o atendimento para outro atendente ou setor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-slider-alt'></i>
                    </div>
                    <h3>Filas de Atendimentos</h3>
                    <p>Crie suas filas de atendimento como por exemplo, Suporte, Comercial, Financeiro e etc..</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-reset'></i>
                    </div>
                    <h3>Respostas rápidas</h3>
                    <p>Cadastre respostas rápidas, para dar mais velocidade ao atendimento.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-bell'></i>
                    </div>
                    <h3>Agendamentos</h3>
                    <p>Agende uma mensagem para seu cliente, escolhendo uma data e horário para esse envio..</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-shape-circle'></i>
                    </div>
                    <h3>API</h3>
                    <p>Utilize nossa API para fazer integração com outras ferramentas via POST para envio de mensagens via texto e imagem..</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<app-pricing-plan></app-pricing-plan>

<!-- Start FAQ Area -->
<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Perguntas frequentes</h2>

                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Preciso deixar o celular ligado?
                            </a>
                            <p class="accordion-content">
                                Não! Através da nova tecnologia Multi Device depois de scanear o Qr-Code, você não precisa da internet dele e nem mesmo o deixar ligado se quiser.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Existe carência ou fidelidade?
                            </a>
                            <p class="accordion-content">
                                O SpotChat é uma plataforma livre de carência e fidelidade. Ou seja você pode deixar de usar quando quiser.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Tenho algum outro custo fora a mensalidade?
                            </a>
                            <p class="accordion-content">
                                Zero custo extra, você paga apenas sua mensalidade.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Preciso instalar no meu computador?
                            </a>
                            <p class="accordion-content">
                                Não! Nosso sistema funciona Online, pode ser acessado de qualquer canto, através do computador ou celular.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Depois que assinar demora quanto tempo para liberar o uso?
                            </a>
                            <p class="accordion-content">
                                Em até 30min Depois da confirmação de pagamento você já pode esta usando o SpotChat.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- <app-clients></app-clients> -->

<app-free-trial></app-free-trial>