<!-- Start Main Banner Area -->
<div class="main-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h1>Great startups for connecting people</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i>Teste Grátis<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="banner-image bg-1">
                    <app-chatting></app-chatting>

                    <img src="assets/img/banner-img1.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape19"><img src="assets/img/shape/18.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<app-partner></app-partner>

<!-- Start Services Area -->
<section class="services-area bg-right-color ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content">
                <div class="content left-content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Path is here for faster way of connections with your customers</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="/" class="default-btn"><i class="bx bxs-spreadsheet"></i> Learn More<span></span></a>
                </div>
            </div>

            <div class="services-image">
                <div class="image">
                    <img src="assets/img/services-image/services1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Services Area -->
<section class="services-area bg-left-color bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/services-image/services2.png" alt="image">
                </div>
            </div>

            <div class="services-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>The best Innovative Chatbot and automations are here to expand</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="/" class="default-btn"><i class="bx bxs-spreadsheet"></i> Learn More<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Features Area -->
<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>The ability to elicit, assessing opportunities</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>Simplify Communication</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-customize'></i>
                    </div>
                    <h3>Drag and Drop Widgets</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-customize'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-slider-alt'></i>
                    </div>
                    <h3>Quick Setup</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-slider-alt'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-reset'></i>
                    </div>
                    <h3>Automatise Chatbots</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-reset'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-bell'></i>
                    </div>
                    <h3>Automated Reminders</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-bell'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-shape-circle'></i>
                    </div>
                    <h3>Machine Learning</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-shape-circle'></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Testimonials Area -->
<section class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>Spacle</span></h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img src="assets/img/author-image/author6.jpg" alt="image">
                        <h3>Michel John</h3>
                        <span>CEO at Envato</span>
                    </div>

                    <div class="testimonials-desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img src="assets/img/author-image/author7.jpg" alt="image">
                        <h3>Sarah Taylor</h3>
                        <span>CEO at EnvyTheme</span>
                    </div>

                    <div class="testimonials-desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img src="assets/img/author-image/author8.jpg" alt="image">
                        <h3>James Andy</h3>
                        <span>CEO at 3S</span>
                    </div>

                    <div class="testimonials-desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials Area -->

<!-- Start Our Loving Clients Area -->
<section class="our-loving-clients ptb-100 bg-color-f8fbfa">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>

        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients1.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients2.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients3.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients4.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients5.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients6.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients7.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients8.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients9.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Our Loving Clients Area -->

<!-- Start Pricing Area -->
<section class="pricing-area pt-100 pb-70 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Choose The Pricing Plan</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <h3>Free</h3>
                    </div>
                    <div class="price">
                        <sup>$</sup>0<sub>/m</sub>
                    </div>
                    <ul class="pricing-features">
                        <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                        <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>
                        <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>
                        <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                        <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>
                        <li><i class="bx bxs-badge-check"></i> Visitor Info</li>
                        <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>
                        <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                        <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>
                        <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                        <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <h3>Starter</h3>
                    </div>
                    <div class="price">
                        <sup>$</sup>49<sub>/m</sub>
                    </div>
                    <ul class="pricing-features">
                        <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                        <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>
                        <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>
                        <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                        <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>
                        <li><i class="bx bxs-badge-check"></i> Visitor Info</li>
                        <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>
                        <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                        <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>
                        <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                        <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <h3>Professional</h3>
                    </div>
                    <div class="price">
                        <sup>$</sup>79<sub>/m</sub>
                    </div>
                    <ul class="pricing-features">
                        <li><i class="bx bxs-badge-check"></i> Up to 5 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                        <li><i class="bx bxs-badge-check"></i> 200 ChatBot Triggers</li>
                        <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>
                        <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                        <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>
                        <li><i class="bx bxs-badge-check"></i> Visitor Info</li>
                        <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>
                        <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                        <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>
                        <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                        <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Area -->

<!-- Start Team Area -->
<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our experts always ready to help you</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>UX/UI Designer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Lee Munroe</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team4.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Calvin Klein</h3>
                        <span>Support</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<!-- Start FAQ Area -->
<section class="faq-area ptb-100 bg-color-f4f5fe">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>Spacle</span></h2>

                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on the free plan?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on a free trial?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Does the price go up as my team gets larger?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                How can I cancel/pause my subscription?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Can I pay via invoicing?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>News and Insights</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog1.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 14, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">50 world-changing people -- We lost in the 2010s</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author1.jpg" alt="image">
                                <h6>Sarah Taylor</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog2.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 16, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">Don't buy a tech gift until you read these rules</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author2.jpg" alt="image">
                                <h6>Michel John</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-details"><img src="assets/img/blog-image/blog3.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 18, 2023</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-details">The golden rule of buying a phone as a gift</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author3.jpg" alt="image">
                                <h6>Lucy Eva</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="blog-notes">
                    <p>Insights to help you do what you do better, faster and more profitably. <a routerLink="/">Read Full Blog</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area bg-f4f5fe">
    <div class="container">
        <div class="subscribe-content">
            <h2>We always try to be as close to you as possible</h2>

            <form class="newsletter-form">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <input type="email" class="input-newsletter" placeholder="hello@spacle.com" name="EMAIL">
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
                    </div>
                </div>
            </form>

            <div class="shape14"><img src="assets/img/shape/13.png" alt="image"></div>
            <div class="shape15"><img src="assets/img/shape/14.png" alt="image"></div>
            <div class="shape16"><img src="assets/img/shape/15.png" alt="image"></div>
            <div class="shape17"><img src="assets/img/shape/16.png" alt="image"></div>
            <div class="shape18"><img src="assets/img/shape/17.png" alt="image"></div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->

<!-- Start Free Trial Area -->
<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Practice active listening and follow through</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
        </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image"></div>
</section>
<!-- End Free Trial Area -->