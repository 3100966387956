<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="spacle-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="logo">
                </a>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <a routerLink="/" routerLinkActive="active" class="nav-link">Home</a>
                        </li>
                    </ul>

                    <div class="others-options">
                        <a href="https:/login.spotchat.com.br/registro" class="default-btn">
                            <i class="bx bxs-hot"></i>Teste Grátis<span></span>
                        </a>
                        
                        <a href="https:/login.spotchat.com.br" class="optional-btn">
                            <i class="bx bx-log-in"></i>Entrar<span></span>
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->